<template>
    <div class="card">
        <div class="card-header">Topdesk</div>
        <div class="card-body">
            <template v-if="loaded === true">
                <b-dropdown size="sm" variant="outline-primary" class="mb-2">
                    <template slot="button-content">
                        <span class="badge badge-primary">{{ selectedCount }}</span> Groepsactie
                    </template>
                    <b-dropdown-item @click="invoice(selected)">Factureren</b-dropdown-item>
                    <b-dropdown-item @click="archive(selected)">Archiveren</b-dropdown-item>
                </b-dropdown>
                <button class="btn btn-sm btn-outline-primary pull-right" disabled v-if="loading_incidents" title="Verversen..."><i class="fa fa-circle-o-notch fa-spin"></i></button>
                <table class="table">
                    <tr>
                        <th style="white-space: nowrap">
                            <b-checkbox ref="toggleSelect" @change="toggleSelectAll"
                                        :checked="selectedCount === incidents.length"></b-checkbox>
                        </th>
                        <th>Incidentnummer</th>
                        <th>Beschrijving</th>
                        <th><b-form-select :options="branches" v-model="selected_branch" value-field="id" text-field="name" size="sm" style="max-width: 150px">
                            <template slot="first">
                                <option :value="undefined">[ Filter vestiging ]</option>
                            </template>
                        </b-form-select></th>
                        <th>Minuten</th>
                        <th>Aangemaakt op</th>
                        <th colspan="3"></th>
                    </tr>
                    <tbody name="fade" is="transition-group">
                    <template v-for="(item, index) in incidents">
                        <tr :key="item.id">
                            <td width="1%">
                                <b-checkbox v-model="selected[index]" :value="item.id"></b-checkbox>
                            </td>
                            <td>{{ item.number }}</td>
                            <td>{{ item.briefDescription }}</td>
                            <td>
                                <template v-if="item.callerBranch">
                                    <a class="text-primary" @click="selected_branch = item.callerBranch.id"><i class="fa fa-filter"></i> {{ item.callerBranch.name }}</a>
                                </template>
                            </td>
                            <td>{{ item.timeSpent }}</td>
                            <td>{{ $moment(item.creationDate).format('DD MMMM YYYY HH:mm:ss') }}</td>
                            <td width="1%">
                                <a class="btn btn-sm btn-outline-primary"
                                   :href="linkToTopdesk(item)"
                                   target="_blank"
                                   style="white-space: nowrap">Open in TOPdesk <i class="fa fa-external-link"></i></a>
                            </td>
                            <td width="1%">
                                <button class="btn btn-sm btn-danger" @click="archive(item)">Archiveren</button>
                            </td>
                            <td width="1%">
                                <button class="btn btn-sm btn-success" @click="invoice([item.id])">Factureren</button>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </template>
            <template v-else-if="loaded === false">
                <p><router-link :to="{name: 'settings.index'}" class="btn btn-outline-primary">Stel TOPdesk API in</router-link></p>
            </template>
            <template v-else>
                <button class="btn btn-sm btn-outline-primary" disabled><i class="fa fa-circle-o-notch fa-spin"></i></button>
            </template>
        </div>
    </div>
</template>

<script>
    import mix from './../settings/mixin';
    import qs from 'qs';

    export default {
        mounted() {
            this.getApiConfig('topdesk').then((response) => {
                if(response.data.config) {
                    this.loaded = true;
                    this.getIncidents();
                    this.getBranches();
                } else {
                    this.loaded = false;
                }
            });
        },
        destroyed() {
            if(this.timeout) {
                clearTimeout(this.timeout);
            }
        },
        mixins: [mix],
        data() {
            return {
                timeout: undefined,
                incidents: [],
                branches: [],
                api_config: {},
                selected: [],
                selected_branch: undefined,
                loaded: null,
                loading_incidents: false,
            }
        },
        watch: {
            selected_branch() {
                this.getIncidents();
            }
        },

        computed: {
            selectedCount() {
                return this.selected.filter((i) => (i)).length;
            },
        },
        methods: {
            /**
             * Get full incident objects from a list of selected ids.
             */
            selectedIncidents(selected) {
                return this.incidents.filter((incident) => {
                   return selected.filter((i) => (i)).indexOf(incident.id) !== -1;
                });
            },
            toggleSelectAll($event) {
                this.incidents.forEach((item, index) => {
                    this.selectRow($event, item, index);
                });
            },
            selectRow($event, item, index) {
                this.$set(this.selected, index, $event ? item.id : false);
            },
            getIncidents() {
                this.loading_incidents = true;
                if(this.timeout) {
                    clearTimeout(this.timeout);
                }

                let query = qs.stringify({
                    status: this.api_config.config.filter_status || '',
                    operator_group : this.api_config.config.operator_group || '',
                    branch: this.selected_branch,
                });

                return this.$http.get(`topdesk/api/incidents?${query}`).then((response) => {
                    this.incidents = response.data;

                    // Also retrieve first timespent to keep API alive
                    if(response.data.hasOwnProperty(0)) {
                        this.$http.get(`topdesk/api/incidents?full-timespent=true&ids[]=` + response.data[0]['id']);
                    }

                    this.loading_incidents = false;
                    this.timeout = setTimeout(() => {
                        this.getIncidents();
                    }, 10000);
                }).catch(() => {
                    // Also retry on error.
                    this.loading_incidents = false;
                    this.timeout = setTimeout(() => {
                        this.getIncidents();
                    }, 10000);
                });
            },
            getBranches() {
                return this.$http.get('topdesk/api/branches').then((response) => {
                    this.branches = response.data;
                });
            },
            linkToTopdesk(incident) {
                let id = incident.id.replace(/-/g, '');
                return this.api_config.config.api_endpoint + `/tas/secure/incident?action=show&unid=${id}`;
            },
            archive(incidents) {
                if(Array.isArray(incidents)) {
                    incidents = this.selectedIncidents(incidents);
                    if(confirm(this.selectedCount + ' items archiveren?')) {
                        return Promise.all(incidents.map((incident) => {
                            return this.$http.put(`topdesk/api/incidents/${incident.id}/archive`);
                        })).then(this.getIncidents);
                    }
                    return Promise.reject();
                } else {
                    if(confirm('Archiveren?')) {
                        return this.$http.put(`topdesk/api/incidents/${incidents.id}/archive`)
                            .then(this.getIncidents);
                    }
                    return Promise.reject();
                }
            },
            /**
             * @param {Array} items
             */
            invoice(items) {
                items = items.filter((i) => (i));
                this.$router.push({name: 'invoicing.index', query: {
                    ids: items
                }});
            }
        }
    }
</script>
